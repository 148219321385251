export function generateFranchiseLdJsonMarkup({
    name = '',
    streetAddress = '',
    addressLocality = '',
    addressRegion = '',
    postalCode = '',
    url = '',
    image = '',
    telephone = '',
    areaServed = {},
    ratingValue = 0,
    bestRating = 5,
    worstRating = 1,
    ratingCount = 0,
    servicesProvided,
    socialMedia,
}) {
    const data = {
        '@context': 'https://schema.org',
        '@type': 'HomeAndConstructionBusiness',
        name,
        image,
        // id,
        address: {
            '@type': 'PostalAddress',
            streetAddress,
            addressLocality,
            addressRegion,
            postalCode,
        },
        telephone,
        url,
        hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Property damage',
            itemListElement: [
                {
                    '@type': 'OfferCatalog',
                    name: 'Property damage cleanup',
                    itemListElement: servicesProvided.map(elem => ({
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Service',
                            name: elem.serviceLine || elem,
                        },
                    })),
                },
            ],
        },
        makesOffer: {
            '@type': 'Offer',
            availability: 'InStock',
            itemOffered: {
                '@type': 'Service',
                serviceType: '24/7 water, fire, smoke, mold, and mildew damage cleanup',
                areaServed: (areaServed?.places || []).map(({
                    city,
                    state,
                    latitude,
                    longitude,
                    wikipediaUrl,
                }) => ({
                    '@type': 'City',
                    name: `${city}, ${state}`,
                    latitude,
                    longitude,
                    sameAs: wikipediaUrl,
                })),
                hasOfferCatalog: {
                    '@type': 'OfferCatalog',
                    name: 'Property damage',
                    itemListElement: [
                        {
                            '@type': 'OfferCatalog',
                            name: 'Property damage cleanup',
                            itemListElement: servicesProvided.filter(elem => !!elem.serviceLine).map(elem => ({
                                '@type': 'Offer',
                                itemOffered: {
                                    '@type': 'Service',
                                    name: elem.serviceLine,
                                },
                            })),
                        },
                    ],
                },
            },
        },
        openingHours: 'Mo,Tu,We,Th,Fr,Sa,Su 24 hours',
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ],
                opens: '00:00',
                closes: '23:59',
            },
        ],
        priceRange: '$$',
        areaServed: (areaServed?.places || []).map(({
            city,
            state,
            latitude,
            longitude,
            wikipediaUrl,
        }) => ({
            '@type': 'City',
            name: `${city}, ${state}`,
            latitude,
            longitude,
            sameAs: wikipediaUrl,
        })),
        aggregateRating: {
            '@type': 'AggregateRating',
            itemReviewed: {
                '@type': 'Thing',
                name: url,
            },
            ratingValue,
            bestRating,
            worstRating,
            ratingCount,
        },
    };

    return JSON.stringify(data);
}
