import './theme.scss';
import './reset.css';
import '../fonts/fonts.css';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { PageProps } from 'gatsby';
import { ResponsiveProvider } from '../components/atoms/Responsive';
import SiteSearchProvider from '../components/organisms/site-search/provider';
import GridContextProvider from '../components/primitives/grid/context';
import { breakpoints } from '../components/primitives/tokens';
import LocatorProvider from '../contexts/franchise/locator-provider';
import { MicrositeProvider } from '../contexts/franchise/microsite-context';
import DniProvider from '../contexts/dni-provider';
import AnnouncementBar from '../components/sections/announcement-bar';
import TemplateProvider from '../contexts/template-provider';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 72, // 72 hours
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});

/*
* 3rd party script for Cookie Compliance
* */
interface OneTrustWindow extends Window {
    OneTrust?: {
        initializeCookiePolicyHtml?: () => void
    };
}

const Layout = ({ children, location, data, pageContext }: PageProps): JSX.Element => {
    React.useEffect(() => {
        const localStoragePersistor = createWebStoragePersistor({
            storage: window.localStorage,
        });

        persistQueryClient({
            queryClient,
            persistor: localStoragePersistor,
        });
    }, []);

    const { pathname } = location;

    React.useEffect(() => {
        const oneTrust = (window as OneTrustWindow).OneTrust;
        if (oneTrust?.initializeCookiePolicyHtml) {
            oneTrust.initializeCookiePolicyHtml();
        }
    }, [pathname]);

    return (
        <TemplateProvider pageContext={pageContext}>
            <GridContextProvider
                breakpoints={{
                    dt: { query: breakpoints.dt, columns: 12, gap: 24 },
                    tb: { query: breakpoints.tb, columns: 12, gap: 24 },
                    mb: { query: breakpoints.mb, columns: 4, gap: 16 },
                }}
                maxWidth={1440}
                desktopFirst
            >
                <QueryClientProvider client={queryClient}>
                    <ResponsiveProvider>
                        <LocatorProvider>
                            <DniProvider data={data}>
                                <MicrositeProvider data={data as any}>
                                    <SiteSearchProvider>
                                        <AnnouncementBar franchise={data?.franchise} />
                                        {children}
                                    </SiteSearchProvider>
                                </MicrositeProvider>
                            </DniProvider>
                        </LocatorProvider>
                    </ResponsiveProvider>
                </QueryClientProvider>
            </GridContextProvider>
        </TemplateProvider>
    );
};

export default Layout;
